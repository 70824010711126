// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDS6n3LinW8cMiJYzVNbosutDZwkqYs38s",
  authDomain: "realtranslate-15050.firebaseapp.com",
  projectId: "realtranslate-15050",
  storageBucket: "realtranslate-15050.appspot.com",
  messagingSenderId: "409027291793",
  appId: "1:409027291793:web:8cd5fb2ccc88a151076d30",
  measurementId: "G-DF36CKE447"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export { app, analytics, auth };