import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircle } from 'lucide-react';

function SuccessScreen() {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="mt-12 bg-white p-6 rounded-lg shadow-lg text-center">
        <CheckCircle size={64} className="text-green-500 mx-auto mb-4" />
        <h2 className="text-3xl font-bold mb-4 text-green-600">Subscription Successful!</h2>
        <p className="text-lg text-gray-600 mb-6">
          Thank you for subscribing! Your subscription has been successfully activated, and you can now enjoy all the premium features.
        </p>
        <button
          onClick={handleGoHome}
          className="px-8 py-4 bg-blue-600 text-white rounded-lg font-bold hover:bg-blue-700 transition-all duration-300"
        >
          Go to Home
        </button>
      </div>
    </div>
  );
}

export default SuccessScreen;

