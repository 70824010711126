import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth, analytics } from '../../firebaseConfig';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { logEvent } from "firebase/analytics";
import Modal from 'react-modal';
import { useLocation, useNavigate } from 'react-router-dom';

const stripePromise = loadStripe('pk_live_51Q9YadHTjZFGR8VK4Q4pxgbmC2fZOjiYDcYPfPQoiDGQtC4L2WCECHFFpvlddIlMpUHig3NjseCW0tT5aqveAGHu00MM9h1Zup');

Modal.setAppElement('#root');

const carouselItems = [
  {
    imageUrl: 'https://pbs.twimg.com/media/Es0NOvSXEAAXfqi.jpg',
    title: 'Unlimited Real-Time Translations',
    description: 'Access real-time transcriptions and translations in over 100 languages.',
  },
  {
    imageUrl: 'https://thumbs.dreamstime.com/b/translation-service-isolated-cartoon-vector-illustrations-professional-synchronic-translators-work-freelance-job-small-business-253366003.jpg',
    title: 'Have Conversations in Real Time in 100 languages',
    description: 'Speak to anyone, anywhere using our real time conversation ability.',
  },
  {
    imageUrl: 'https://mir-s3-cdn-cf.behance.net/project_modules/hd/7964c0108300933.5fbb01b5f06cd.png',
    title: 'Save to Phrasebook',
    description: 'Save your translations to a personalized phrasebook for easy reference.',
  },
];

function CheckoutScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const isDismissible = location.state?.isDismissible;
  const returnUrl = location.state?.returnUrl;

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(1);
  const provider = new GoogleAuthProvider();

  useEffect(() => {
    logEvent(analytics, 'screen_view', {
      screen_name: 'checkout_screen',
      screen_class: 'CheckoutScreen',
      is_dismissible: isDismissible
    });

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        logEvent(analytics, 'user_data', {
          user_id: currentUser.uid,
          user_email_domain: currentUser.email?.split('@')[1],
          is_new_user: currentUser.metadata.creationTime === currentUser.metadata.lastSignInTime
        });

        if (selectedPlan) {
          setIsModalOpen(false);
          initiateCheckout();
        }
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [auth, selectedPlan]);

  const handleDismiss = () => {
    // Make sure hasSeenInitialCheckout is set in localStorage before dismissing
    localStorage.setItem('hasSeenInitialCheckout', 'true');
    
    logEvent(analytics, 'checkout_dismissed', {
      user_id: user?.uid || 'anonymous',
      selected_plan: selectedPlan,
      return_url: returnUrl
    });
  
    if (returnUrl) {
      navigate(returnUrl);
    } else {
      navigate('/');
    }
  };

  const handleScroll = (e) => {
    const element = e.target;
    const scrollPosition = element.scrollLeft;
    const slideWidth = element.offsetWidth;
    const currentIndex = Math.round(scrollPosition / slideWidth);
    
    if (currentIndex !== activeIndex) {
      setActiveIndex(currentIndex);
      logEvent(analytics, 'carousel_slide_view', {
        slide_index: currentIndex,
        slide_title: carouselItems[currentIndex].title
      });
    }
  };

  const scrollToSlide = (index) => {
    const carousel = document.querySelector('.carousel-container');
    if (carousel) {
      carousel.scrollTo({
        left: index * carousel.offsetWidth,
        behavior: 'smooth'
      });
    }
    setActiveIndex(index);
    
    logEvent(analytics, 'carousel_dot_click', {
      slide_index: index,
      slide_title: carouselItems[index].title
    });
  };

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
    logEvent(analytics, 'select_plan', {
      plan_type: plan,
      user_id: user?.uid || 'anonymous',
      price: plan === 'annual' ? 49.99 : 2.99,
      currency: 'USD'
    });
  };

  const handlePurchase = () => {
    if (!selectedPlan) return;

    logEvent(analytics, 'checkout_start', {
      plan_type: selectedPlan,
      user_id: user?.uid || 'anonymous',
      price: selectedPlan === 'annual' ? 49.99 : 2.99,
      currency: 'USD',
      is_authenticated: !!user
    });

    if (!user) {
      setIsModalOpen(true);
      logEvent(analytics, 'auth_prompt', {
        trigger: 'checkout_attempt',
        plan_type: selectedPlan
      });
      return;
    }

    initiateCheckout();
  };

  const initiateCheckout = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://fwoneexl99.execute-api.us-east-2.amazonaws.com/prod/createCheckout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          plan: selectedPlan, 
          customerId: user.uid, 
          email: user.email,
          returnUrl: returnUrl || window.location.origin // Include return URL
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create checkout session');
      }

      const { sessionId } = await response.json();
      const stripe = await stripePromise;

      logEvent(analytics, 'checkout_progress', {
        step: 'redirect_to_stripe',
        plan_type: selectedPlan,
        user_id: user.uid,
        session_id: sessionId
      });

      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error('Error creating checkout session:', error);
      logEvent(analytics, 'checkout_error', {
        error_message: error.message,
        plan_type: selectedPlan,
        user_id: user?.uid
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSignIn = async () => {
    try {
      logEvent(analytics, 'login_attempt', {
        method: 'google',
        context: 'checkout'
      });

      const result = await signInWithPopup(auth, provider);
      
      logEvent(analytics, 'login_success', {
        method: 'google',
        user_id: result.user.uid,
        is_new_user: result.user.metadata.creationTime === result.user.metadata.lastSignInTime
      });
    } catch (error) {
      console.error("Error signing in with Google", error);
      logEvent(analytics, 'login_error', {
        error_code: error.code,
        error_message: error.message,
        method: 'google'
      });
    }
  };

  return (
    <div className="max-w-lg mx-auto px-4 py-8 bg-white min-h-screen">
      {/* Dismiss button */}
      {isDismissible && (
        <div className="mb-8">
          <button
            onClick={handleDismiss}
            className="text-gray-600 hover:text-gray-800 flex items-center gap-2"
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-5 w-5" 
              viewBox="0 0 20 20" 
              fill="currentColor"
            >
              <path 
                fillRule="evenodd" 
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" 
                clipRule="evenodd" 
              />
            </svg>
            Back to Translator
          </button>
        </div>
      )}

      {/* Carousel Section */}
      <div className="mb-12 overflow-hidden">
        <div 
          className="carousel-container flex overflow-x-auto snap-x snap-mandatory no-scrollbar"
          onScroll={handleScroll}
        >
          {carouselItems.map((item, index) => (
            <div key={index} className="w-full flex-none snap-center px-6">
              <img 
                src={item.imageUrl} 
                alt={item.title}
                className="w-full h-72 object-contain mb-8 rounded-2xl"
              />
              <h3 className="text-2xl font-semibold text-center mb-3 text-gray-800">
                {item.title}
              </h3>
              <p className="text-gray-600 text-center text-lg leading-relaxed">
                {item.description}
              </p>
            </div>
          ))}
        </div>

        {/* Carousel Navigation */}
        <div className="flex justify-center space-x-3 mt-8">
          {carouselItems.map((_, index) => (
            <button
              key={index}
              onClick={() => scrollToSlide(index)}
              className={`w-2.5 h-2.5 rounded-full transition-all duration-300 ${
                activeIndex === index 
                  ? 'bg-blue-600 w-4' 
                  : 'bg-gray-300 hover:bg-gray-400'
              }`}
              aria-label={`Go to slide ${index + 1}`}
            />
          ))}
        </div>
      </div>

      {/* Pro Access Section */}
      <div className="mt-12">
        <h2 className="text-4xl font-bold text-blue-600 text-center mb-4">
          Unlock Pro Access
        </h2>
        <p className="text-center text-gray-600 text-lg mb-10">
          Enjoy unlimited access to all features and exclusive content with our Pro version!
        </p>
        
        {/* Subscription Plans */}
        <div className="space-y-4">
          <button
            onClick={() => handleSelectPlan('annual')}
            className={`w-full p-5 rounded-2xl border-2 transition-all duration-200 ${
              selectedPlan === 'annual' 
                ? 'border-blue-600 bg-blue-50 shadow-lg transform scale-[1.02]' 
                : 'border-gray-200 hover:border-blue-300 hover:bg-gray-50'
            }`}
          >
            <div className="flex justify-between items-center">
              <span className="text-2xl font-bold text-blue-600">Annual</span>
              <span className="bg-blue-600 text-white text-sm font-semibold px-4 py-1.5 rounded-full shadow-sm">
                68% OFF
              </span>
            </div>
            <p className="text-gray-600 mt-2 text-lg">
              Unlimited and Full access for just $49.99
            </p>
          </button>

          <button
            onClick={() => handleSelectPlan('weekly')}
            className={`w-full p-5 rounded-2xl border-2 transition-all duration-200 ${
              selectedPlan === 'weekly' 
                ? 'border-blue-600 bg-blue-50 shadow-lg transform scale-[1.02]' 
                : 'border-gray-200 hover:border-blue-300 hover:bg-gray-50'
            }`}
          >
            <span className="text-2xl font-bold text-blue-600">Weekly</span>
            <p className="text-gray-600 mt-2 text-lg">
              Unlimited and Full access for just $2.99
            </p>
          </button>

          <div className="flex flex-col gap-4 mt-8">
            <button
              onClick={handlePurchase}
              disabled={!selectedPlan || loading}
              className="w-full py-4 bg-blue-600 text-white text-lg font-semibold rounded-2xl 
                shadow-lg transition-all duration-200 hover:bg-blue-700 transform hover:scale-[1.02]
                disabled:bg-gray-400 disabled:cursor-not-allowed disabled:transform-none"
            >
              {loading ? 'Processing...' : 'Continue'}
            </button>

            {isDismissible && (
              <button
                onClick={handleDismiss}
                className="w-full py-4 text-gray-600 text-lg font-semibold rounded-2xl 
                  border-2 border-gray-200 transition-all duration-200 hover:bg-gray-50"
              >
                Maybe Later
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Sign In Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Sign In Required"
        className="fixed inset-0 flex items-center justify-center p-4"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"
      >
        <div className="bg-white rounded-2xl shadow-2xl p-8 max-w-md w-full mx-4">
          <h3 className="text-2xl font-bold mb-6 text-center text-gray-800">Sign In to Continue</h3>
          <p className="text-gray-600 mb-8 text-center">To continue with your purchase, please sign in with Google.</p>
          <button
            onClick={handleSignIn}
            className="w-full bg-blue-600 text-white font-semibold py-4 rounded-xl 
              shadow-lg transition-all duration-200 hover:bg-blue-700 transform hover:scale-[1.02]"
          >
            Sign In with Google
          </button>
          <button
            onClick={() => setIsModalOpen(false)}
            className="w-full mt-4 bg-gray-100 text-gray-700 font-semibold py-4 rounded-xl
              transition-all duration-200 hover:bg-gray-200"
          >
            Cancel
          </button>
        </div>
      </Modal>

      <style jsx global>{`
        .no-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        .no-scrollbar::-webkit-scrollbar {display: none;
        }
      `}</style>
    </div>
  );
}

export default CheckoutScreen;