import React, { useState } from 'react';
import { Search, Star, CheckCircle } from 'lucide-react';
import { LANGUAGES } from '../data/languages';

function LanguageSelector({ title, selectedLanguage, onSelectLanguage, showDetect = false }) {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredLanguages = LANGUAGES.filter(lang => 
    lang.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (showDetect || lang.id !== 'detect')
  );

  const getAlphabetLetters = () => {
    const letters = new Set(LANGUAGES.map(lang => lang.name[0].toUpperCase()));
    return Array.from(letters).sort();
  };

  return (
    <div className="fixed inset-0 bg-gradient-to-b from-blue-50 to-white z-50 overflow-auto md:max-w-lg md:relative md:rounded-lg md:shadow-2xl">
      <div className="p-6 border-b border-blue-200">
        <h2 className="text-2xl font-bold text-center text-blue-600 md:text-left">{title}</h2>
      </div>
      <div className="p-6">
        <div className="relative mb-6">
          <input
            type="text"
            placeholder="Search for a language"
            className="w-full p-4 pl-12 border-2 border-blue-300 rounded-lg shadow-sm focus:outline-none focus:ring-4 focus:ring-blue-500 text-lg"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="absolute left-4 top-4 text-blue-400" size={24} />
        </div>
        <ul className="space-y-4 max-h-[65vh] overflow-y-auto">
          {filteredLanguages.map((lang) => (
            <li 
              key={lang.id} 
              className="flex items-center p-4 bg-white rounded-lg shadow-md hover:shadow-lg hover:bg-blue-50 cursor-pointer transition-transform transform hover:scale-105"
              onClick={() => onSelectLanguage(lang)}
            >
              {lang.id === 'detect' ? (
                <Star className="mr-3 text-yellow-500" size={24} />
              ) : (
                <CheckCircle className={`mr-3 ${selectedLanguage.id === lang.id ? 'text-blue-500' : 'text-gray-300'}`} size={24} />
              )}
              <span className="text-xl font-medium text-gray-800">{lang.name}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="p-6 border-t border-blue-200 flex justify-center">
        <div className="flex flex-wrap justify-center">
          {getAlphabetLetters().map((letter) => (
            <button 
              key={letter} 
              className="text-blue-600 font-semibold text-lg m-2 px-3 py-1 hover:underline"
              onClick={() => setSearchTerm(letter)}
            >
              {letter}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default LanguageSelector;
