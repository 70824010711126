import React, { useState, useRef, useEffect, useContext } from 'react';
import { Upload, Camera, Image as ImageIcon, Loader, ChevronDown } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import LanguageSelector from '../components/LanguageSelector';
import { SubscriptionContext } from '../context/SubscriptionContext';

const API_ENDPOINT = 'https://me25gad3gc.execute-api.us-east-2.amazonaws.com/prod/vision';

function ImageTranslation() {
  const [imagePreview, setImagePreview] = useState(null);
  const [translatedText, setTranslatedText] = useState('');
  const [isTranslating, setIsTranslating] = useState(false);
  const [fromLanguage, setFromLanguage] = useState({ id: 'en', name: 'English' });
  const [toLanguage, setToLanguage] = useState({ id: 'fr', name: 'French' });
  const [showFromSelector, setShowFromSelector] = useState(false);
  const [showToSelector, setShowToSelector] = useState(false);
  const [translationCount, setTranslationCount] = useState(localStorage.getItem('translationCount') || 0);
  const [hasRedirected, setHasRedirected] = useState(localStorage.getItem('hasRedirected') === 'true');
  const fileInputRef = useRef(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const navigate = useNavigate();
  const { isSubscribed } = useContext(SubscriptionContext);

  useEffect(() => {
    // Update translation count in local storage whenever it changes
    localStorage.setItem('translationCount', translationCount);
    localStorage.setItem('hasRedirected', hasRedirected);
  }, [translationCount, hasRedirected]);

  const handleImageUpload = (e) => {
    if (shouldRedirectToCheckout()) {
      navigate('/checkout');
      return;
    }
    
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        sendImageToAPI(reader.result.split(',')[1]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCameraCapture = () => {
    if (shouldRedirectToCheckout()) {
      navigate('/checkout');
      return;
    }

    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      })
      .catch((err) => {
        console.error('Error accessing the camera:', err);
      });
  };

  const capturePhoto = () => {
    if (shouldRedirectToCheckout()) {
      navigate('/checkout');
      return;
    }

    const context = canvasRef.current.getContext('2d');
    context.drawImage(videoRef.current, 0, 0, 640, 480);
    const imageDataUrl = canvasRef.current.toDataURL('image/jpeg');
    setImagePreview(imageDataUrl);
    sendImageToAPI(imageDataUrl.split(',')[1]);

    // Stop the video stream
    const stream = videoRef.current.srcObject;
    const tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());
  };

  const sendImageToAPI = async (base64Image) => {
    setIsTranslating(true);
    try {
      const payload = {
        image: base64Image,
        from_language: fromLanguage.name,
        to_language: toLanguage.name,
      };

      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Translation request failed');
      }

      const result = await response.json();
      setTranslatedText(result.translatedText);

      // Increment the translation count after a successful translation
      setTranslationCount((prevCount) => Number(prevCount) + 1);
    } catch (error) {
      console.error('Translation error:', error);
      alert('Failed to translate the text. Please try again.');
    } finally {
      setIsTranslating(false);
    }
  };

  const shouldRedirectToCheckout = () => {
    // If the user is not subscribed, has reached the free limit, and hasn't already been redirected
    if (!isSubscribed && translationCount >= 1 && !hasRedirected) {
      setHasRedirected(true);
      return true;
    }
    return false;
  };

  return (
    <div className="max-w-4xl mx-auto p-4 md:p-6">
      <h1 className="text-3xl md:text-4xl font-bold text-center mb-8 text-blue-600">Image Translation</h1>

      <div className="flex justify-between mb-8">
        <div className="relative">
          <button
            onClick={() => setShowFromSelector(!showFromSelector)}
            className="px-4 py-2 border rounded-md bg-white shadow-sm hover:bg-gray-50 flex items-center"
          >
            From: {fromLanguage.name} <ChevronDown className="ml-2" size={20} />
          </button>
          {showFromSelector && (
            <div className="absolute z-10 mt-1">
              <LanguageSelector
                title="Translate from"
                selectedLanguage={fromLanguage}
                onSelectLanguage={(lang) => {
                  setFromLanguage(lang);
                  setShowFromSelector(false);
                }}
                showDetect={true}
              />
            </div>
          )}
        </div>
        <div className="relative">
          <button
            onClick={() => setShowToSelector(!showToSelector)}
            className="px-4 py-2 border rounded-md bg-white shadow-sm hover:bg-gray-50 flex items-center"
          >
            To: {toLanguage.name} <ChevronDown className="ml-2" size={20} />
          </button>
          {showToSelector && (
            <div className="absolute z-10 mt-1 right-0">
              <LanguageSelector
                title="Translate to"
                selectedLanguage={toLanguage}
                onSelectLanguage={(lang) => {
                  setToLanguage(lang);
                  setShowToSelector(false);
                }}
              />
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 mb-8">
        <div className="w-full md:w-1/2">
          {imagePreview ? (
            <div className="w-full h-64 md:h-96 bg-gray-100 rounded-lg overflow-hidden">
              <img src={imagePreview} alt="Uploaded" className="w-full h-full object-contain" />
            </div>
          ) : (
            <div className="w-full h-64 md:h-96 border-2 border-dashed border-blue-300 rounded-lg flex flex-col items-center justify-center text-blue-500">
              <ImageIcon size={48} />
              <p className="mt-4 text-center">Upload an image or take a photo</p>
            </div>
          )}
        </div>
        <div className="w-full md:w-1/2 bg-gray-100 p-6 rounded-lg">
          <h2 className="text-2xl font-semibold mb-4 text-blue-600">Translation Result</h2>
          {isTranslating ? (
            <div className="flex items-center justify-center">
              <Loader className="animate-spin mr-2" />
              <span>Translating...</span>
            </div>
          ) : translatedText ? (
            <p className="text-gray-800">{translatedText}</p>
          ) : (
            <p className="text-gray-600">Translated text will appear here after processing the image.</p>
          )}
        </div>
      </div>

      <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4">
        <label className="w-full md:w-auto flex items-center justify-center px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200 cursor-pointer">
          <input ref={fileInputRef} type="file" accept="image/*" onChange={handleImageUpload} className="hidden" />
          <Upload size={24} />
          <span className="ml-2">Upload Image</span>
        </label>
        <button
          onClick={handleCameraCapture}
          className="w-full md:w-auto flex items-center justify-center px-6 py-3 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors duration-200"
        >
          <Camera size={24} />
          <span className="ml-2">Take Photo</span>
        </button>
      </div>

      {/* Hidden video and canvas elements for camera capture */}
      <video ref={videoRef} className="hidden" width="640" height="480" />
      <canvas ref={canvasRef} className="hidden" width="640" height="480" />
    </div>
  );
}

export default ImageTranslation;
