export const LANGUAGES = [
  { id: 'detect', name: 'Detect language', icon: 'star-outline', bcp47: '' },
  { id: 'af', name: 'Afrikaans', icon: 'checkmark-circle-outline', bcp47: 'af' },
  { id: 'ar', name: 'Arabic', icon: 'checkmark-circle-outline', bcp47: 'ar' },
  { id: 'bg', name: 'Bulgarian', icon: 'checkmark-circle-outline', bcp47: 'bg' },
  { id: 'bn', name: 'Bengali', icon: 'checkmark-circle-outline', bcp47: 'bn' },
  { id: 'ca', name: 'Catalan', icon: 'checkmark-circle-outline', bcp47: 'ca' },
  { id: 'cs', name: 'Czech', icon: 'checkmark-circle-outline', bcp47: 'cs' },
  { id: 'cy', name: 'Welsh', icon: 'checkmark-circle-outline', bcp47: 'cy' },
  { id: 'da', name: 'Danish', icon: 'checkmark-circle-outline', bcp47: 'da' },
  { id: 'de', name: 'German', icon: 'checkmark-circle-outline', bcp47: 'de-DE' },
  { id: 'el', name: 'Greek', icon: 'checkmark-circle-outline', bcp47: 'el' },
  { id: 'en', name: 'English', icon: 'checkmark-circle-outline', bcp47: 'en-US' },
  { id: 'es', name: 'Spanish', icon: 'checkmark-circle-outline', bcp47: 'es-ES' },
  { id: 'et', name: 'Estonian', icon: 'checkmark-circle-outline', bcp47: 'et' },
  { id: 'fa', name: 'Persian', icon: 'checkmark-circle-outline', bcp47: 'fa' },
  { id: 'fi', name: 'Finnish', icon: 'checkmark-circle-outline', bcp47: 'fi' },
  { id: 'fr', name: 'French', icon: 'checkmark-circle-outline', bcp47: 'fr-FR' },
  { id: 'gu', name: 'Gujarati', icon: 'checkmark-circle-outline', bcp47: 'gu' },
  { id: 'he', name: 'Hebrew', icon: 'checkmark-circle-outline', bcp47: 'he' },
  { id: 'hi', name: 'Hindi', icon: 'checkmark-circle-outline', bcp47: 'hi' },
  { id: 'hr', name: 'Croatian', icon: 'checkmark-circle-outline', bcp47: 'hr' },
  { id: 'hu', name: 'Hungarian', icon: 'checkmark-circle-outline', bcp47: 'hu' },
  { id: 'id', name: 'Indonesian', icon: 'checkmark-circle-outline', bcp47: 'id' },
  { id: 'it', name: 'Italian', icon: 'checkmark-circle-outline', bcp47: 'it-IT' },
  { id: 'ja', name: 'Japanese', icon: 'checkmark-circle-outline', bcp47: 'ja' },
  { id: 'kn', name: 'Kannada', icon: 'checkmark-circle-outline', bcp47: 'kn' },
  { id: 'ko', name: 'Korean', icon: 'checkmark-circle-outline', bcp47: 'ko' },
  { id: 'lt', name: 'Lithuanian', icon: 'checkmark-circle-outline', bcp47: 'lt' },
  { id: 'lv', name: 'Latvian', icon: 'checkmark-circle-outline', bcp47: 'lv' },
  { id: 'ml', name: 'Malayalam', icon: 'checkmark-circle-outline', bcp47: 'ml' },
  { id: 'mr', name: 'Marathi', icon: 'checkmark-circle-outline', bcp47: 'mr' },
  { id: 'ms', name: 'Malay', icon: 'checkmark-circle-outline', bcp47: 'ms' },
  { id: 'nl', name: 'Dutch', icon: 'checkmark-circle-outline', bcp47: 'nl-NL' },
  { id: 'no', name: 'Norwegian', icon: 'checkmark-circle-outline', bcp47: 'no' },
  { id: 'pa', name: 'Punjabi', icon: 'checkmark-circle-outline', bcp47: 'pa' },
  { id: 'pl', name: 'Polish', icon: 'checkmark-circle-outline', bcp47: 'pl' },
  { id: 'pt', name: 'Portuguese', icon: 'checkmark-circle-outline', bcp47: 'pt-PT' },
  { id: 'ro', name: 'Romanian', icon: 'checkmark-circle-outline', bcp47: 'ro' },
  { id: 'ru', name: 'Russian', icon: 'checkmark-circle-outline', bcp47: 'ru' },
  { id: 'sk', name: 'Slovak', icon: 'checkmark-circle-outline', bcp47: 'sk' },
  { id: 'sl', name: 'Slovenian', icon: 'checkmark-circle-outline', bcp47: 'sl' },
  { id: 'so', name: 'Somali', icon: 'checkmark-circle-outline', bcp47: 'so' },
  { id: 'sq', name: 'Albanian', icon: 'checkmark-circle-outline', bcp47: 'sq' },
  { id: 'sv', name: 'Swedish', icon: 'checkmark-circle-outline', bcp47: 'sv-SE' },
  { id: 'sw', name: 'Swahili', icon: 'checkmark-circle-outline', bcp47: 'sw' },
  { id: 'ta', name: 'Tamil', icon: 'checkmark-circle-outline', bcp47: 'ta' },
  { id: 'te', name: 'Telugu', icon: 'checkmark-circle-outline', bcp47: 'te' },
  { id: 'th', name: 'Thai', icon: 'checkmark-circle-outline', bcp47: 'th' },
  { id: 'tr', name: 'Turkish', icon: 'checkmark-circle-outline', bcp47: 'tr' },
  { id: 'uk', name: 'Ukrainian', icon: 'checkmark-circle-outline', bcp47: 'uk' },
  { id: 'ur', name: 'Urdu', icon: 'checkmark-circle-outline', bcp47: 'ur' },
  { id: 'vi', name: 'Vietnamese', icon: 'checkmark-circle-outline', bcp47: 'vi' },
  { id: 'zh', name: 'Chinese', icon: 'checkmark-circle-outline', bcp47: 'zh' },
];
