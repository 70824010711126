import { Mic, ArrowLeftRight } from 'lucide-react';
import { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import LanguageSelector from '../components/LanguageSelector';
import { SubscriptionContext } from '../context/SubscriptionContext';

function ConversationTranslation() {
  const navigate = useNavigate();
  const { isSubscribed } = useContext(SubscriptionContext);
  const [recognition, setRecognition] = useState(null);
  const [isListening, setIsListening] = useState(false);
  const [activeMic, setActiveMic] = useState(null);
  const [fromLanguage, setFromLanguage] = useState({ id: 'en', name: 'English' });
  const [toLanguage, setToLanguage] = useState({ id: 'fr', name: 'French' });
  const [topText, setTopText] = useState('');
  const [bottomText, setBottomText] = useState('');
  const [topTranslation, setTopTranslation] = useState('');
  const [bottomTranslation, setBottomTranslation] = useState('');
  const [showFromLanguageSelector, setShowFromLanguageSelector] = useState(false);
  const [showToLanguageSelector, setShowToLanguageSelector] = useState(false);
  const [interimText, setInterimText] = useState('');
  const [translationCount, setTranslationCount] = useState(
    parseInt(localStorage.getItem('translationCount')) || 0
  );
  const [hasRedirected, setHasRedirected] = useState(
    localStorage.getItem('hasRedirected') === 'true'
  );

  const topScrollRef = useRef(null);
  const bottomScrollRef = useRef(null);
  const translationTimer = useRef(null);

  useEffect(() => {
    localStorage.setItem('translationCount', translationCount);
    localStorage.setItem('hasRedirected', hasRedirected);
  }, [translationCount, hasRedirected]);

  const shouldRedirectToCheckout = () => {
    if (!isSubscribed && translationCount >= 1 && !hasRedirected) {
      setHasRedirected(true);
      return true;
    }
    return false;
  };

  const cleanupRecognition = () => {
    if (recognition) {
      recognition.stop();
    }
    setIsListening(false);
    setActiveMic(null);
    setInterimText('');
  };

  const clearTexts = (position) => {
    if (position === 'top') {
      setTopText('');
      setBottomTranslation('');
    } else {
      setBottomText('');
      setTopTranslation('');
    }
    setInterimText('');
  };

  useEffect(() => {
    if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
      const SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
      const recognitionInstance = new SpeechRecognition();
      recognitionInstance.continuous = true;
      recognitionInstance.interimResults = true;

      recognitionInstance.onstart = () => {
        setIsListening(true);
      };

      recognitionInstance.onend = () => {
        cleanupRecognition();
      };

      recognitionInstance.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
        cleanupRecognition();
      };

      recognitionInstance.onresult = (event) => {
        let interimTranscript = '';
        let finalTranscript = '';

        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript;
          
          if (event.results[i].isFinal) {
            finalTranscript += transcript;
            if (activeMic === 'top') {
              setTopText(transcript);
              translateText(transcript, 'top');
            } else {
              setBottomText(transcript);
              translateText(transcript, 'bottom');
            }
          } else {
            interimTranscript += transcript;
          }
        }

        setInterimText(interimTranscript);

        if (interimTranscript) {
          if (translationTimer.current) {
            clearTimeout(translationTimer.current);
          }
          translationTimer.current = setTimeout(() => {
            translateText(interimTranscript, activeMic);
          }, 500);
        }
      };

      setRecognition(recognitionInstance);

      return () => {
        cleanupRecognition();
      };
    }
  }, [activeMic]);

  const translateText = async (text, position) => {
    if (!text.trim()) return;

    try {
      const sourceLang = position === 'top' ? fromLanguage.id : toLanguage.id;
      const targetLang = position === 'top' ? toLanguage.id : fromLanguage.id;
      
      const response = await fetch('https://me25gad3gc.execute-api.us-east-2.amazonaws.com/prod/translate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text: text,
          languageFrom: sourceLang,
          languageTo: targetLang,
          userId: 'user-id-placeholder',
          startNewConversation: false,
        }),
      });

      const data = await response.json();
      
      setTranslationCount(prevCount => {
        const newCount = prevCount + 1;
        if (shouldRedirectToCheckout()) {
          navigate('/checkout');
        }
        return newCount;
      });

      if (position === 'top') {
        setBottomTranslation(data.translatedText);
      } else {
        setTopTranslation(data.translatedText);
      }
    } catch (error) {
      console.error('Translation failed:', error);
    }
  };

  const handleMicClick = async (position) => {
    if (shouldRedirectToCheckout()) {
      navigate('/checkout');
      return;
    }

    if (!recognition) return;

    if (position === activeMic && isListening) {
      cleanupRecognition();
    } else {
      if (isListening) {
        cleanupRecognition();
      }
      
      clearTexts(position);
      
      recognition.lang = position === 'top' ? fromLanguage.id : toLanguage.id;
      setActiveMic(position);
      recognition.start();
    }
  };

  const handleFromLanguageSelect = (language) => {
    setFromLanguage(language);
    setShowFromLanguageSelector(false);
  };

  const handleToLanguageSelect = (language) => {
    setToLanguage(language);
    setShowToLanguageSelector(false);
  };

  const getDisplayText = (position) => {
    if (position === 'top') {
      return activeMic === 'top' && isListening ? (topText + interimText) : topText;
    } else {
      return activeMic === 'bottom' && isListening ? (bottomText + interimText) : bottomText;
    }
  };

  return (
    <div className="min-h-screen bg-white flex flex-col">
      <div className="flex-1 flex flex-col">
        {/* Top half section - remains exactly the same */}
        <div className="flex-1 p-4 flex flex-col justify-center">
          <div 
            ref={topScrollRef}
            className="w-full h-full flex flex-col justify-center items-center text-center"
          >
            {getDisplayText('top') || topTranslation ? (
              <>
                <p className="text-lg text-gray-800 mb-2">
                  {getDisplayText('top')}
                  {activeMic === 'top' && isListening && (
                    <span className="inline-block w-0.5 h-5 bg-[#000fff] ml-1 animate-pulse">|</span>
                  )}
                </p>
                {topTranslation && (
                  <p className="text-base text-gray-600 italic">{topTranslation}</p>
                )}
              </>
            ) : (
              <p className="text-2xl text-gray-400">Tap mic to start</p>
            )}
          </div>
        </div>

        <div className="h-px bg-gray-200 mx-4" />

        {/* Bottom half section - remains exactly the same */}
        <div className="flex-1 p-4 flex flex-col justify-center">
          <div 
            ref={bottomScrollRef}
            className="w-full h-full flex flex-col justify-center items-center text-center"
          >
            {getDisplayText('bottom') || bottomTranslation ? (
              <>
                <p className="text-lg text-gray-800 mb-2">
                  {getDisplayText('bottom')}
                  {activeMic === 'bottom' && isListening && (
                    <span className="inline-block w-0.5 h-5 bg-[#000fff] ml-1 animate-pulse">|</span>
                  )}
                </p>
                {bottomTranslation && (
                  <p className="text-base text-gray-600 italic">{bottomTranslation}</p>
                )}
              </>
            ) : (
              <p className="text-2xl text-gray-400">Tap mic to start</p>
            )}
          </div>
        </div>
      </div>

      {/* Bottom control section with updated mic animations */}
      <div className="bg-[#000fff] p-6 rounded-t-3xl">
        <div className="flex items-center justify-center space-x-4 mb-6">
          <button 
            onClick={() => setShowFromLanguageSelector(true)}
            className="px-6 py-2 bg-white rounded-md text-[#000fff] font-medium hover:bg-blue-50 transition-colors"
          >
            {fromLanguage.name}
          </button>
          <ArrowLeftRight className="text-white" size={24} />
          <button 
            onClick={() => setShowToLanguageSelector(true)}
            className="px-6 py-2 bg-white rounded-md text-[#000fff] font-medium hover:bg-blue-50 transition-colors"
          >
            {toLanguage.name}
          </button>
        </div>

        <div className="flex justify-around">
  <div className="relative">
    <button
      onClick={() => handleMicClick('top')}
      className={`p-4 rounded-full transition-all duration-200 ${
        activeMic === 'top' && isListening
          ? 'bg-red-500'
          : 'bg-white hover:bg-blue-50'
      }`}
    >
      {activeMic === 'top' && isListening && (
        <div className="absolute inset-0">
          <span className="absolute inset-0 rounded-full bg-red-500 animate-ping" />
          <span className="absolute inset-0 rounded-full bg-red-500/70 animate-pulse" />
        </div>
      )}
      <Mic
        size={28}
        className={`relative z-10 ${
          activeMic === 'top' && isListening 
            ? 'text-white animate-bounce' 
            : 'text-[#000fff]'
        }`}
        style={{
          animation: activeMic === 'top' && isListening ? 'bounce 1s infinite' : 'none'
        }}
      />
    </button>
    {activeMic === 'top' && isListening && (
      <div className="absolute -inset-2 rounded-full animate-pulse bg-red-500/20" />
    )}
  </div>

  <div className="relative">
    <button
      onClick={() => handleMicClick('bottom')}
      className={`p-4 rounded-full transition-all duration-200 ${
        activeMic === 'bottom' && isListening
          ? 'bg-red-500'
          : 'bg-white hover:bg-blue-50'
      }`}
    >
      {activeMic === 'bottom' && isListening && (
        <div className="absolute inset-0">
          <span className="absolute inset-0 rounded-full bg-red-500 animate-ping" />
          <span className="absolute inset-0 rounded-full bg-red-500/70 animate-pulse" />
        </div>
      )}
      <Mic
        size={28}
        className={`relative z-10 ${
          activeMic === 'bottom' && isListening 
            ? 'text-white animate-bounce' 
            : 'text-[#000fff]'
        }`}
        style={{
          animation: activeMic === 'bottom' && isListening ? 'bounce 1s infinite' : 'none'
        }}
      />
    </button>
    {activeMic === 'bottom' && isListening && (
      <div className="absolute -inset-2 rounded-full animate-pulse bg-red-500/20" />
    )}
  </div>
</div>
      </div>

      {/* Language Selectors - remain exactly the same */}
      {showFromLanguageSelector && (
        <div className="fixed inset-0 z-50">
          <LanguageSelector
            title="Select From Language"
            selectedLanguage={fromLanguage}
            onSelectLanguage={handleFromLanguageSelect}
          />
        </div>
      )}
      {showToLanguageSelector && (
        <div className="fixed inset-0 z-50">
          <LanguageSelector
            title="Select To Language"
            selectedLanguage={toLanguage}
            onSelectLanguage={handleToLanguageSelect}
          />
        </div>
      )}
    </div>
  );
}

export default ConversationTranslation;