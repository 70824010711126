import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation, Navigate, useNavigate } from 'react-router-dom';
import { 
  Type, 
  Image, 
  MessageSquare, 
  Menu, 
  UserCircle, 
  BookmarkIcon, 
  X, 
  Store, 
  Smartphone,
  ChevronLeft,
  ChevronRight
} from 'lucide-react';
import Modal from 'react-modal';
import Home from './screens/Home';
import TextTranslation from './screens/TextTranslation';
import ConversationTranslation from './screens/ConversationTranslation';
import SavedTranslations from './screens/SavedTranslationScreen';
import ImageTranslation from './screens/ImageTranslation';
import CheckoutScreen from './screens/CheckoutScreen';
import NotFound from './screens/NotFound';
import AuthComponent from '../src/components/AuthComponent';
import logo from './logo.jpg';
import screenshot1 from './assets/1.png';
import screenshot2 from './assets/2.png';
import screenshot3 from './assets/3.png';
import screenshot4 from './assets/4.png';
import screenshot5 from './assets/5.png';
import { auth, analytics } from './firebaseConfig';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { logEvent } from "firebase/analytics";
import { SubscriptionProvider } from './context/SubscriptionContext'; // Ensure correct import path
import SuccessScreen from './screens/SuccessScreen';


Modal.setAppElement('#root');

const appScreenshots = [
  screenshot1,
  screenshot2,
  screenshot3,
  screenshot4,
  screenshot5
];
// First, update the AppShowcaseModal component
function AppShowcaseModal({ isOpen, onClose }) {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    if (isOpen) {
      const interval = setInterval(() => {
        setCurrentSlide((prev) => (prev === appScreenshots.length - 1 ? 0 : prev + 1));
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [isOpen]);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === appScreenshots.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? appScreenshots.length - 1 : prev - 1));
  };

  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const storeUrl = isIOS 
    ? "https://apps.apple.com/us/app/realtranslate-ai-translator/id6670330491"
    : "";

  const handleDownloadClick = () => {
    logEvent(analytics, 'app_download_click', {
      platform: isIOS ? 'ios' : 'android',
      source: 'showcase_modal'
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="App Showcase Modal"
      className="fixed inset-0 flex items-center justify-center px-4 py-6"
      overlayClassName="fixed inset-0 bg-black bg-opacity-75"
      style={{
        content: {
          position: 'relative',
          padding: 0,
          border: 'none',
          background: 'transparent',
          overflow: 'visible'
        }
      }}
    >
      <div className="bg-white rounded-xl shadow-xl w-full max-w-sm mx-auto flex flex-col" style={{ maxHeight: 'calc(100vh - 48px)' }}>
        <button
          onClick={onClose}
          className="absolute -top-2 -right-2 bg-white rounded-full p-1 shadow-lg z-50 hover:bg-gray-100 transition-colors"
        >
          <X size={20} className="text-gray-600" />
        </button>

        <div className="relative flex-1 min-h-0">
          <div className="relative w-full" style={{ height: 'calc(100vh - 230px)' }}>
            {appScreenshots.map((screenshot, index) => (
              <div
                key={index}
                className={`absolute inset-0 transition-opacity duration-300 ${
                  index === currentSlide ? 'opacity-100' : 'opacity-0'
                }`}
              >
                <img
                  src={screenshot}
                  alt={`App Screenshot ${index + 1}`}
                  className="w-full h-full object-contain"
                />
              </div>
            ))}

            <button
              onClick={prevSlide}
              className="absolute left-2 top-1/2 -translate-y-1/2 bg-white/80 rounded-full p-1.5 hover:bg-white transition-colors shadow-lg"
            >
              <ChevronLeft size={20} />
            </button>
            <button
              onClick={nextSlide}
              className="absolute right-2 top-1/2 -translate-y-1/2 bg-white/80 rounded-full p-1.5 hover:bg-white transition-colors shadow-lg"
            >
              <ChevronRight size={20} />
            </button>

            <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex space-x-2">
              {appScreenshots.map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentSlide(index)}
                  className={`w-1.5 h-1.5 rounded-full transition-colors ${
                    index === currentSlide ? 'bg-blue-500' : 'bg-gray-300'
                  }`}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="p-4 bg-white shrink-0">
          <h2 className="text-lg font-bold mb-1">
            Experience RealTranslate at its Best
          </h2>
          <p className="text-sm text-gray-600 mb-3">
            Get instant translations, offline support, and more features on our mobile app.
          </p>
          <a
            href={storeUrl}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleDownloadClick}
            className="block w-full bg-blue-600 text-white py-2.5 px-4 rounded-lg font-medium hover:bg-blue-700 transition-colors text-center text-sm"
          >
            Download Now
          </a>
        </div>
      </div>
    </Modal>
  );
}

function MobileAppBanner() {
  const [isVisible, setIsVisible] = useState(false);
  const [isShowcaseModalOpen, setIsShowcaseModalOpen] = useState(false);

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const hasClosedBanner = localStorage.getItem('mobileBannerClosed');
    const hasSeenShowcase = localStorage.getItem('hasSeenAppShowcase');
    
    setIsVisible(isMobile && !hasClosedBanner);
    
    if (isMobile && !hasSeenShowcase) {
      const timer = setTimeout(() => {
        setIsShowcaseModalOpen(true);
        localStorage.setItem('hasSeenAppShowcase', 'true');
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, []);

  if (!isVisible) return null;

  const closeBanner = () => {
    localStorage.setItem('mobileBannerClosed', 'true');
    setIsVisible(false);
  };

  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const isAndroid = /Android/i.test(navigator.userAgent);

  return (
    <>
      <div className="bg-blue-500 text-white px-4 py-2.5">
        <div className="flex items-center justify-between max-w-7xl mx-auto">
          <div className="flex items-center flex-1 min-w-0">
            <Smartphone size={18} className="shrink-0 mr-2" />
            <span className="text-sm font-medium truncate">
              Get the RealTranslate app
            </span>
          </div>
          <div className="flex items-center space-x-2 ml-2">
            {isIOS && (
              <a
                href="https://apps.apple.com/us/app/realtranslate-ai-translator/id6670330491"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-white text-blue-500 px-2.5 py-1 rounded-full text-xs font-medium flex items-center whitespace-nowrap"
              >
                <Store size={12} className="mr-1" />
                App Store
              </a>
            )}
            {isAndroid && (
              <a
                href="https://apps.apple.com/us/app/realtranslate-ai-translator/id6670330491"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-white text-blue-500 px-2.5 py-1 rounded-full text-xs font-medium flex items-center whitespace-nowrap"
              >
                <Store size={12} className="mr-1" />
                Play Store
              </a>
            )}
            <button
              onClick={() => setIsShowcaseModalOpen(true)}
              className="text-white hover:text-gray-100 text-xs underline"
            >
              Preview
            </button>
            <button
              onClick={closeBanner}
              className="text-white hover:text-gray-100 p-1"
              aria-label="Close banner"
            >
              <X size={16} />
            </button>
          </div>
        </div>
      </div>
      
      <AppShowcaseModal
        isOpen={isShowcaseModalOpen}
        onClose={() => setIsShowcaseModalOpen(false)}
      />
    </>
  );
}

function AppContent() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSavedModalOpen, setIsSavedModalOpen] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser && isSavedModalOpen) {
        setIsSavedModalOpen(false);
        navigate('/saved');
      }
    });

    return () => unsubscribe();
  }, [isSavedModalOpen, navigate]);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  const handleSavedClick = () => {
    if (!user) {
      setIsSavedModalOpen(true);
    } else {
      navigate('/saved');
    }
  };

  return (
    <div className="min-h-screen bg-white">
      <MobileAppBanner />
      <nav className="bg-blue-600 shadow-md">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <Link to="/" className="flex-shrink-0 flex items-center">
                <img src={logo} alt="RealTranslate Logo" className="h-8 w-8 mr-2" />
                <span className="text-xl font-semibold text-white">RealTranslate</span>
              </Link>
            </div>
            <div className="hidden md:flex items-center space-x-4">
              <NavLink to="/text" icon={<Type size={20} />} text="Text" />
              <NavLink to="/image" icon={<Image size={20} />} text="Image" />
              <NavLink to="/conversation" icon={<MessageSquare size={20} />} text="Conversation" />
              <button
                onClick={handleSavedClick}
                className="flex items-center px-3 py-2 rounded-md text-sm font-medium text-white hover:bg-blue-700 transition-colors duration-200"
              >
                <BookmarkIcon size={20} className="mr-2" />
                Saved
              </button>
              {user && (
                <button
                  onClick={handleSignOut}
                  className="flex items-center px-3 py-2 rounded-md text-sm font-medium text-white hover:bg-blue-700 transition-colors duration-200"
                >
                  <UserCircle size={20} className="mr-2" />
                  Sign Out
                </button>
              )}
            </div>
            <div className="md:hidden flex items-center space-x-2">
              <button
                onClick={handleSavedClick}
                className="text-white p-2 hover:bg-blue-700 rounded-md"
              >
                <BookmarkIcon size={24} />
              </button>
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="text-white p-2 hover:bg-blue-700 rounded-md"
              >
                <Menu size={24} />
              </button>
            </div>
          </div>
        </div>
        {isMenuOpen && (
          <div className="md:hidden">
            <NavLink to="/text" icon={<Type size={20} />} text="Text" mobile />
            <NavLink to="/image" icon={<Image size={20} />} text="Image" mobile />
            <NavLink to="/conversation" icon={<MessageSquare size={20} />} text="Conversation" mobile />
          </div>
        )}
      </nav>

      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/text" element={<TextTranslation />} />
          <Route path="/text/:source/:target" element={<TextTranslation />} />
          <Route path="/translate/:source-:target" element={<TranslationRedirect />} />
          <Route path="/image" element={<ImageTranslation />} />
          <Route path="/conversation" element={<ConversationTranslation />} />
          <Route path="/saved" element={<SavedTranslations />} />
          <Route path="/checkout" element={<CheckoutScreen />} />
          <Route path='/success' element={<SuccessScreen />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>

      <Modal
        isOpen={isSavedModalOpen}
        onRequestClose={() => setIsSavedModalOpen(false)}
        contentLabel="Saved Translations Modal"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-xl w-96">
          <h2 className="text-2xl font-bold mb-4">Access Saved Translations</h2>
          <p className="mb-4 text-gray-600">Sign in to access your saved translations</p>
          {!user && <AuthComponent />}
          <button
            onClick={() => setIsSavedModalOpen(false)}
            className="mt-4 w-full bg-gray-200 text-gray-800 py-2 px-4 rounded hover:bg-gray-300 transition-colors duration-200"
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
}

// Main App component that provides the Router context
function App() {
  return (
    <Router>
      <SubscriptionProvider>
        <AnalyticsTracker />
        <AppContent />
      </SubscriptionProvider>
    </Router>
  );
}

function TranslationRedirect() {
  const location = useLocation();
  const path = location.pathname;
  const match = path.match(/\/translate\/(.+)-(.+)/);
  
  if (match) {
    const [, source, target] = match;
    return <Navigate to={`/text/${source}/${target}`} replace />;
  }
  
  return <NotFound />;
}

function AnalyticsTracker() {
  const location = useLocation();

  useEffect(() => {
    const pageTitle = document.title;
    const pageUrl = window.location.href;

    logEvent(analytics, 'page_view', {
      page_title: pageTitle,
      page_location: pageUrl,
      page_path: location.pathname,
    });
  }, [location]);

  return null;
}

function NavLink({ to, icon, text, mobile }) {
  const baseClasses = "flex items-center px-3 py-2 rounded-md text-sm font-medium text-white hover:bg-blue-700 transition-colors duration-200";
  const mobileClasses = mobile ? "block w-full" : "";
  
  return (
    <Link to={to} className={`${baseClasses} ${mobileClasses}`}>
      {icon}
      <span className="ml-2">{text}</span>
    </Link>
  );
}

export default App;