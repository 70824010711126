import React, { useState, useEffect } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDS6n3LinW8cMiJYzVNbosutDZwkqYs38s",
  authDomain: "realtranslate-15050.firebaseapp.com",
  projectId: "realtranslate-15050",
  storageBucket: "realtranslate-15050.appspot.com",
  messagingSenderId: "409027291793",
  appId: "1:409027291793:web:8cd5fb2ccc88a151076d30",
  measurementId: "G-DF36CKE447"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

function AuthComponent() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSignIn = async () => {
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error("Error signing in with Google", error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  return (
    <div className="flex items-center justify-end p-4">
      {user ? (
        <div className="flex items-center">
          <img
            src={user.photoURL}
            alt="User avatar"
            className="w-8 h-8 rounded-full mr-2"
          />
          <span className="mr-4">{user.displayName}</span>
          <button
            onClick={handleSignOut}
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
          >
            Sign Out
          </button>
        </div>
      ) : (
        <button
          onClick={handleSignIn}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
        >
          Sign In with Google
        </button>
      )}
    </div>
  );
}

export default AuthComponent;