import React, { useState, useCallback, useEffect, useMemo, useContext, useRef } from 'react';
import { ArrowRightLeft, Volume2, Clipboard, BookmarkIcon } from 'lucide-react';
import LanguageSelector from '../components/LanguageSelector';
import { LANGUAGES } from '../data/languages';
import debounce from 'lodash/debounce';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getAuth } from "firebase/auth";
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import AuthComponent from '../components/AuthComponent';
import Modal from 'react-modal';
import { SubscriptionContext } from '../context/SubscriptionContext';
import { auth, analytics } from '../firebaseConfig';
import { logEvent } from "firebase/analytics";


Modal.setAppElement('#root');

function TextTranslation() {
  const { source, target } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const redirectState = location.state || {};
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(true);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const isFreshTranslationRef = useRef(false);
  const prevInputText = useRef('');
  const db = getFirestore();
  const { isSubscribed } = useContext(SubscriptionContext);
  const auth = getAuth();

  // State for tracking if user has seen initial checkout
  const [hasSeenInitialCheckout, setHasSeenInitialCheckout] = useState(() => {
    return localStorage.getItem('hasSeenInitialCheckout') === 'true';
  });

  // State for translation history
  const [translationsHistory, setTranslationsHistory] = useState(() => {
    const stored = localStorage.getItem('translationsHistory');
    return stored ? JSON.parse(stored) : [];
  });

  const findLanguage = (identifier) => {
    if (!identifier) return null;
    const normalizedIdentifier = identifier.toLowerCase();
    return LANGUAGES.find(
      lang => lang.id.toLowerCase() === normalizedIdentifier || 
              lang.name.toLowerCase() === normalizedIdentifier
    );
  };

  const [sourceLanguage, setSourceLanguage] = useState(() => {
    return findLanguage(source) || 
           findLanguage(redirectState.source) || 
           LANGUAGES.find(lang => lang.id === 'en');
  });

  const [targetLanguage, setTargetLanguage] = useState(() => {
    return findLanguage(target) || 
           findLanguage(redirectState.target) || 
           LANGUAGES.find(lang => lang.id === 'es');
  });

  const [showSourceSelector, setShowSourceSelector] = useState(false);
  const [showTargetSelector, setShowTargetSelector] = useState(false);
  const [inputText, setInputText] = useState('');
  const [translatedText, setTranslatedText] = useState('');
  const [isTranslating, setIsTranslating] = useState(false);
  const [user, setUser] = useState(null);

  const handleMaybeLater = () => {
    logEvent(analytics, 'limit_modal_action', {
      action: 'dismiss',
      user_id: user?.uid || 'anonymous',
      translations_count: getCurrentTranslationCount(),
      next_available: getTimeUntilNext() ? `${getTimeUntilNext().hours}h ${getTimeUntilNext().minutes}m` : 'N/A',
      context: 'translation'
    });
    
    setShowSubscribeModal(false);
  };
  
  const handleSubscribeClick = () => {
    logEvent(analytics, 'limit_modal_action', {
      action: 'subscribe',
      user_id: user?.uid || 'anonymous',
      translations_count: getCurrentTranslationCount(),
      next_available: getTimeUntilNext() ? `${getTimeUntilNext().hours}h ${getTimeUntilNext().minutes}m` : 'N/A',
      context: 'translation'
    });
  
    navigate('/checkout', { 
      state: { 
        from: 'translation',
        returnUrl: window.location.pathname,
        source: 'limit_modal'
      } 
    });
  };

  // Store translations history
  useEffect(() => {
    localStorage.setItem('translationsHistory', JSON.stringify(translationsHistory));
  }, [translationsHistory]);

  // Store hasSeenInitialCheckout state
  useEffect(() => {
    localStorage.setItem('hasSeenInitialCheckout', hasSeenInitialCheckout.toString());
  }, [hasSeenInitialCheckout]);

  // Clean up old translations
  useEffect(() => {
    const twentyFourHoursAgo = Date.now() - (24 * 60 * 60 * 1000);
    const filtered = translationsHistory.filter(timestamp => timestamp > twentyFourHoursAgo);
    if (filtered.length !== translationsHistory.length) {
      setTranslationsHistory(filtered);
    }
  }, [translationsHistory]);

  // Get current translation count
  const getCurrentTranslationCount = useCallback(() => {
    const twentyFourHoursAgo = Date.now() - (24 * 60 * 60 * 1000);
    return translationsHistory.filter(timestamp => timestamp > twentyFourHoursAgo).length;
  }, [translationsHistory]);

  

  // Get time until next translation
  const getTimeUntilNext = useCallback(() => {
    if (translationsHistory.length === 0) return null;
    const twentyFourHoursAgo = Date.now() - (24 * 60 * 60 * 1000);
    const oldestInWindow = translationsHistory
      .filter(timestamp => timestamp > twentyFourHoursAgo)
      .sort((a, b) => a - b)[0];
    
    if (!oldestInWindow) return null;
    
    const timeUntilExpiry = oldestInWindow + (24 * 60 * 60 * 1000) - Date.now();
    if (timeUntilExpiry <= 0) return null;

    const hours = Math.floor(timeUntilExpiry / (60 * 60 * 1000));
    const minutes = Math.floor((timeUntilExpiry % (60 * 60 * 1000)) / (60 * 1000));
    return { hours, minutes };
  }, [translationsHistory]);

  // Handle initial checkout redirect
  useEffect(() => {
    const currentCount = getCurrentTranslationCount();
    if (!isSubscribed && currentCount >= 5 && !hasSeenInitialCheckout) {
      setHasSeenInitialCheckout(true);
      navigate('/checkout', { 
        state: { 
          from: 'translation',
          returnUrl: window.location.pathname,
          isDismissible: true
        } 
      });
    }
  }, [getCurrentTranslationCount, isSubscribed, hasSeenInitialCheckout, navigate]);

  // Handle auth state
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user && showAuthModal) {
        setShowAuthModal(false);
      }
    });
    return () => unsubscribe();
  }, [auth, showAuthModal]);

  // Handle URL params for languages
  useEffect(() => {
    const newSourceLang = findLanguage(source) || findLanguage(redirectState.source);
    const newTargetLang = findLanguage(target) || findLanguage(redirectState.target);

    if (newSourceLang) setSourceLanguage(newSourceLang);
    if (newTargetLang) setTargetLanguage(newTargetLang);
  }, [source, target, redirectState.source, redirectState.target]);

  

  const showNotification = (message, success = true) => {
    setToastMessage(message);
    setIsSuccess(success);
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  };

  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();
      if (text) setInputText(text);
    } catch (error) {
      console.error('Failed to paste:', error);
      showNotification('Failed to paste from clipboard. Please try manually.', false);
    }
  };

  const saveTranslation = async () => {
    if (!user) {
      setShowAuthModal(true);
      return;
    }

    if (!inputText || !translatedText) {
      showNotification('Please enter text to translate first', false);
      return;
    }

    try {
      await addDoc(collection(db, 'savedConversations'), {
        userId: user.uid,
        fromLanguage: sourceLanguage.id,
        toLanguage: targetLanguage.id,
        inputText,
        translatedText,
        timestamp: serverTimestamp(),
      });
      showNotification('Translation saved successfully!', true);
    } catch (error) {
      console.error('Error saving translation:', error);
      showNotification('Failed to save translation. Please try again.', false);
    }
  };

  const performTranslation = useCallback(async (text, sourceLang, targetLang) => {
    if (!text.trim()) {
      setTranslatedText('');
      return;
    }
  
    if (isFreshTranslationRef.current && !isSubscribed) {
      setTranslationsHistory(prev => [...prev, Date.now()]);
      isFreshTranslationRef.current = false;
    }
  
    setIsTranslating(true);
  
    try {
      const response = await fetch(
        'https://me25gad3gc.execute-api.us-east-2.amazonaws.com/prod/translate',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            text,
            languageFrom: sourceLang,
            languageTo: targetLang,
            userId: user ? user.uid : 'anonymous',
            startNewConversation: true,
          }),
        }
      );
  
      if (!response.ok) throw new Error('Translation API response was not ok');
  
      const data = await response.json();
      setTranslatedText(data.translatedText);
    } catch (error) {
      console.error('Translation error:', error);
      setTranslatedText('An error occurred during translation.');
    } finally {
      setIsTranslating(false);
    }
  }, [user, isSubscribed]);

  useEffect(() => {
    return () => {
      setShowSubscribeModal(false);
    };
  }, []);

  const debouncedTranslate = useMemo(
    () => debounce((text, sourceLang, targetLang) => {
      const currentCount = getCurrentTranslationCount();
      if (!isSubscribed && currentCount >= 5) {
        setShowSubscribeModal(true);
        return;
      }
      performTranslation(text, sourceLang, targetLang);
    }, 200),
    [performTranslation, getCurrentTranslationCount, isSubscribed]
  );

  // Reset translation flag when input changes
  useEffect(() => {
    if (!prevInputText.current.trim() && inputText.trim()) {
      isFreshTranslationRef.current = true;
    } else if (!inputText.trim()) {
      isFreshTranslationRef.current = false;
    }
    prevInputText.current = inputText;
  }, [inputText]);

  // Handle input text changes
  useEffect(() => {
    if (inputText.trim()) {
      const currentCount = getCurrentTranslationCount();
      if (!isSubscribed && currentCount >= 5) {
        setShowSubscribeModal(true);
        return;
      }
      debouncedTranslate(inputText, sourceLanguage.id, targetLanguage.id);
    } else {
      setTranslatedText('');
    }
  
    return () => debouncedTranslate.cancel();
  }, [inputText, debouncedTranslate, sourceLanguage.id, targetLanguage.id, isSubscribed, getCurrentTranslationCount]);

  const handleSwapLanguages = () => {
    const tempLang = sourceLanguage;
    setSourceLanguage(targetLanguage);
    setTargetLanguage(tempLang);
  };

  const speakText = useCallback((text, language) => {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = language.bcp47 || language.id;
      speechSynthesis.speak(utterance);
    }
  }, []);

  return (
    <div className="min-h-screen bg-white flex flex-col">
      {/* Translation limit banner */}
      {!isSubscribed && getCurrentTranslationCount() >= 5 && (
        <div className="bg-blue-50 px-4 py-2 text-center flex items-center justify-center space-x-2">
          <span className="text-sm text-blue-600">
            Translation limit reached
            {getTimeUntilNext() && (
              <span>
                {' '}(Next translation available in {getTimeUntilNext().hours}h {getTimeUntilNext().minutes}m)
              </span>
            )}
          </span>
        </div>
      )}

      {/* Rest of your existing UI components */}
      {/* Language selector section */}
      <div className="sticky top-0 bg-white z-10 px-4 py-3 md:py-4 border-b border-gray-200">
        <div className="max-w-4xl mx-auto flex items-center justify-center space-x-4">
          <button 
            onClick={() => setShowSourceSelector(true)}
            className="flex-1 max-w-[200px] py-3 px-4 border border-[#000fff] rounded-lg text-[#000fff] font-medium text-center bg-white hover:bg-blue-50 transition-colors"
          >
            {sourceLanguage.name}
          </button>
          
          <button 
            onClick={handleSwapLanguages}
            className="p-2 hover:bg-blue-50 rounded-full transition-colors"
          >
            <ArrowRightLeft className="w-6 h-6 text-[#000fff]" />
          </button>
          
          <button 
            onClick={() => setShowTargetSelector(true)}
            className="flex-1 max-w-[200px] py-3 px-4 border border-[#000fff] rounded-lg text-[#000fff] font-medium text-center bg-white hover:bg-blue-50 transition-colors"
          >
            {targetLanguage.name}
          </button>
        </div>
      </div>

      {/* Main translation areas */}
      {/* Keep your existing translation areas code */}

      {/* Language Selectors */}
      {showSourceSelector && (
        <div className="fixed inset-0 z-50">
          <LanguageSelector
            title="Translate from"
            selectedLanguage={sourceLanguage}
            onSelectLanguage={(lang) => {
              setSourceLanguage(lang);
              setShowSourceSelector(false);
            }}
            showDetect={true}
          />
        </div>
      )}
      {showTargetSelector && (
        <div className="fixed inset-0 z-50">
          <LanguageSelector
            title="Translate to"
            selectedLanguage={targetLanguage}
            onSelectLanguage={(lang) => {
              setTargetLanguage(lang);
              setShowTargetSelector(false);
            }}
          />
        </div>
      )}

      {/* Subscribe Modal */}
      <Modal
        isOpen={showSubscribeModal}
        onRequestClose={handleMaybeLater}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            backdropFilter: 'blur(4px)',
            zIndex: 1000
          },
          content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '440px',
            width: '90%',
            padding: 0,
            border: 'none',
            background: 'transparent',
            overflow: 'visible'
          }
        }}
        className="outline-none"
      >
        <div className="p-6 bg-white rounded-lg shadow-xl">
          <h2 className="text-xl font-bold mb-4">Translation Limit Reached</h2>
          <p className="mb-4">
            You've reached your limit of 5 free translations in 24 hours.
            {getTimeUntilNext() && (
              <span className="block mt-2">
                Next translation will be available in {getTimeUntilNext().hours}h {getTimeUntilNext().minutes}m
              </span>
            )}
          </p>
          <p className="mb-6">
            Subscribe now to get unlimited translations and additional features.
          </p>
          <div className="flex justify-end space-x-3">
            <button
              onClick={() => setShowSubscribeModal(false)}
              className="px-4 py-2 text-gray-600 hover:text-gray-800"
            >
              Maybe Later
            </button>
            <button
              onClick={handleSubscribeClick}
              className="px-4 py-2 bg-[#000fff] text-white rounded-lg hover:bg-blue-700"
            >
              Subscribe Now
            </button>
          </div>
        </div>
      </Modal>

      {/* Main content area */}
      <div className="flex-1 flex flex-col md:flex-row max-w-4xl mx-auto w-full p-4 gap-4">
        {/* Source text area */}
        <div className="flex-1 flex flex-col">
          <div className="flex-1 flex flex-col bg-white rounded-lg relative">
            <textarea
              className="w-full flex-1 text-xl p-4 border-none resize-none focus:ring-0 focus:outline-none min-h-[300px] md:min-h-[400px]"
              placeholder="Enter text"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
            />
            <div className="absolute bottom-2 left-2 right-2 flex items-center justify-between px-2">
              <button 
                onClick={() => speakText(inputText, sourceLanguage)}
                className={`p-2 ${inputText ? 'text-[#000fff]' : 'text-gray-300'} hover:bg-blue-50 rounded-full transition-colors`}
              >
                <Volume2 className="w-6 h-6" />
              </button>
              {inputText && (
                <button 
                  onClick={saveTranslation}
                  className="p-2 text-[#000fff] hover:bg-blue-50 rounded-full transition-colors"
                  title="Save translation"
                >
                  <BookmarkIcon size={20} className="w-6 h-6" />
                </button>
              )}
            </div>
          </div>
          {!inputText && (
            <button
              onClick={handlePaste}
              className="flex items-center gap-2 text-gray-500 mt-2 hover:text-gray-700 transition-colors self-start"
            >
              <Clipboard className="w-5 h-5" />
              <span>Paste</span>
            </button>
          )}
        </div>
  
        {/* Translation text area */}
        <div className="flex-1 flex flex-col">
          <div className="flex-1 flex flex-col bg-white rounded-lg relative">
            <textarea
              className="w-full flex-1 text-xl p-4 border-none resize-none focus:ring-0 focus:outline-none min-h-[300px] md:min-h-[400px] text-[#000fff]"
              placeholder="Translation"
              value={translatedText}
              readOnly
            />
            <div className="absolute bottom-2 left-2 right-2 flex items-center justify-between px-2">
              <button 
                onClick={() => speakText(translatedText, targetLanguage)}
                className={`p-2 ${translatedText ? 'text-[#000fff]' : 'text-gray-300'} hover:bg-blue-50 rounded-full transition-colors`}
              >
                <Volume2 className="w-6 h-6" />
              </button>
              {translatedText && (
                <button 
                  onClick={saveTranslation}
                  className="p-2 text-[#000fff] hover:bg-blue-50 rounded-full transition-colors"
                  title="Save translation"
                >
                  <BookmarkIcon size={20} className="w-6 h-6" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Toast notification */}
      {showToast && (
        <div className={`fixed top-4 right-4 z-50 p-4 rounded-lg shadow-lg ${
          isSuccess ? 'bg-green-500' : 'bg-red-500'
        } text-white`}>
          {toastMessage}
        </div>
      )}

      {/* Auth Modal */}
      <Modal
        isOpen={showAuthModal}
        onRequestClose={() => setShowAuthModal(false)}
        className="modal"
        overlayClassName="modal-overlay"
      >
        <AuthComponent onClose={() => setShowAuthModal(false)} />
      </Modal>
    </div>
  );
}

export default TextTranslation;