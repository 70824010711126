import React, { createContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

export const SubscriptionContext = createContext();

export const SubscriptionProvider = ({ children }) => {
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in.
        const userId = user.uid;

        try {
          // Fetch subscription status from Firestore
          const docRef = doc(db, 'userSubscriptions', userId);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();
            if (userData.isSubscribed) {
              setIsSubscribed(true);
            } else {
              setIsSubscribed(false);
            }
          } else {
            setIsSubscribed(false);
          }
        } catch (error) {
          console.error('Error fetching subscription status:', error);
          setIsSubscribed(false);
        }
      } else {
        // User is signed out.
        setIsSubscribed(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const subscribe = () => {
    setIsSubscribed(true);
  };

  return (
    <SubscriptionContext.Provider value={{ isSubscribed, subscribe }}>
      {children}
    </SubscriptionContext.Provider>
  );
};
